import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'

import { removeAlertSignature } from '../../../actions/alerts'
import Company from '../../../model/company'
import LeaveType from '../../../model/leaveType'
import TimeRegistration from '../../../model/timeRegistration'
import { Day } from '../../../model/types'
import { AlertReducer } from '../../../reducers/alerts'
import { formatAPIDate, formatDate, getDateForWeekDay } from '../../../utils/date-utils'
import { formatDay, week } from '../../../utils/day-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { formatLeaveTypeName } from '../../../utils/format-utils'
import {
  forceParseInputNumber,
  formatInputAsMinutes,
  formatInputNumber,
  formatMinutesAsTime,
  formatNumber,
  parseInputNumber,
  parseTimeAsMinutes,
} from '../../../utils/number-utils'
import { setByPath } from '../../../utils/object-utils'
import { decapitalise } from '../../../utils/string-utils'
import { t } from '../../../utils/translation-utils'
import Form from '../../antd/form'
import Button from '../../elements/button'
import { Col, Row } from '../../elements/grid'
import Icon from '../../elements/icon'
import Input from '../../elements/input/Input'
import Tooltip from '../../elements/tooltip'
import Alerts from '../../widgets/Alerts'

type BasicTimeReg = {
  hours?: number
  start?: number
  end?: number
}

type Props = {
  company: Company
  weekStart: Date
  workWeek: Day[]
  registrations: Partial<Record<Day, BasicTimeReg[]>>
  templateMode: boolean
}

type RegistrationProps = Props & {
  templateMode: false
  leaveRegistrations: TimeRegistration[]
  leaveTypes: List<LeaveType>
  templateEnabled: boolean
}

type TemplateProps = Props & {
  templateMode: true
  companyTemplate: boolean
  hasEmployeeTemplate: boolean
  hasCompanyTemplate: boolean
  alerts: AlertReducer
  removeAlert: removeAlertSignature

  onDelete: () => void
  onNone: () => void
}

type Fields = Partial<Record<`${Day}-hours`, string>> &
  Partial<Record<`${Day}-startTime`, string>> &
  Partial<Record<`${Day}-endTime`, string>> &
  Partial<Record<`${Day}-breakMinutes`, string>>

type ResultDay = {
  date: Date
  hours: number
  start?: number
  end?: number
}

export type WorkHoursResult = {
  days: ResultDay[]
}

function WorkHoursWeekForm(
  props: (RegistrationProps | TemplateProps) & FormComponentProps<Fields, WorkHoursResult>
): ReactElement | null {
  const [disabled, setDisabled] = useState(
    props.templateMode && !props.companyTemplate && !props.hasEmployeeTemplate && props.hasCompanyTemplate
  )
  const { decorateField, getFieldValue, getFieldError } = props

  const doTimeBasedRegistration = (): boolean => {
    if (week.some((day) => !!props.getFieldValue(`${day}-startTime`) || !!props.getFieldValue(`${day}-endTime`))) {
      // one of the registrations is set
      return true
    }
    return props.company.settingsEnabled.some((setting) => setting === 'RegisterWorkHoursStartEnd')
  }

  const remove = (day: Day) => {
    return () => {
      props.setFieldValue(`${day}-hours`, undefined)
      props.setFieldValue(`${day}-startTime`, undefined)
      props.setFieldValue(`${day}-endTime`, undefined)
      props.setFieldValue(`${day}-breakMinutes`, undefined)
    }
  }

  const unlock = () => {
    setDisabled(false)
    week.forEach((d) => {
      remove(d)()
    })
  }

  return (
    <div className="work-hours-week-form">
      {props.templateMode && <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />}
      {props.templateMode && props.companyTemplate && (
        <p>{t('work_hours_registration_tab.simple_form.company_template_intro')}</p>
      )}
      <p>{t('work_hours_registration_tab.simple_form.intro')}</p>
      {!doTimeBasedRegistration() && (
        <div className="work-hours-week-form-hours-week">
          {week.map((day) => {
            if (!props.workWeek.some((d) => d === day)) {
              return <div key={day} className="work-hours-week-form-hours-day"></div>
            }
            return (
              <div key={day} className="work-hours-week-form-hours-day">
                {decorateField(`${day}-hours`, {
                  placeholder: formatDay(day),
                  skipWrapper: true,
                })(<Input disabled={disabled} />)}
              </div>
            )
          })}
        </div>
      )}
      {doTimeBasedRegistration() && (
        <>
          <Row>
            <Col span={6}>{t('work_hours_registration_tab.simple_form.time_based.header.day')}</Col>
            <Col span={6}>{t('work_hours_registration_tab.simple_form.time_based.header.start')}</Col>
            <Col span={6}>{t('work_hours_registration_tab.simple_form.time_based.header.end')}</Col>
            <Col span={5}>{t('work_hours_registration_tab.simple_form.time_based.header.break')}</Col>
          </Row>
          {week.map((day) => {
            if (!props.workWeek.some((d) => d === day)) {
              return null
            }
            const date = formatAPIDate(getDateForWeekDay(props.weekStart, day))
            const leave = props.templateMode ? [] : props.leaveRegistrations.filter((reg) => reg.date === date)
            const hasError =
              getFieldError(`${day}-startTime`) ||
              getFieldError(`${day}-endTime`) ||
              getFieldError(`${day}-breakMinutes`)
            return (
              <Form.Item key={day} validateStatus={hasError ? 'error' : 'success'}>
                {hasError && (
                  <Row style={{ marginBottom: '-15px' }}>
                    <Col span={6}>&nbsp;</Col>
                    <Col span={18} className="form-error">
                      {getFieldError(`${day}-startTime`) ??
                        getFieldError(`${day}-endTime`) ??
                        getFieldError(`${day}-breakMinutes`)}
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col span={6} className="text-cell work-hours-week-form-week-day">
                    <Tooltip title={formatDate(date)}>{formatDay(day)}</Tooltip>
                    {!props.templateMode && leave.length > 0 && (
                      <Tooltip
                        overlayClassName={'work-hours-week-form-leave-note-tooltip'}
                        title={
                          <>
                            <p>
                              {t(
                                props.templateEnabled
                                  ? 'work_hours_registration_tab.simple_form.leave_on_date.note.has_templates'
                                  : 'work_hours_registration_tab.simple_form.leave_on_date.note.no_templates'
                              )}
                              <ul>
                                {leave
                                  .map((l, i) => {
                                    const leaveType = props.leaveTypes.find((type) => type.id === l.leaveTypeID)
                                    if (!leaveType) {
                                      return null
                                    }
                                    return (
                                      <li key={i}>
                                        {t('work_hours_registration_tab.simple_form.leave_on_date.item', {
                                          name: decapitalise(formatLeaveTypeName(leaveType.name, (l.days ?? 0) != 1)),
                                          amount: formatNumber(l.days ?? 0),
                                        })}
                                      </li>
                                    )
                                  })
                                  .filter((l) => !!l)}
                              </ul>
                            </p>
                          </>
                        }
                      >
                        <span className="work-hours-week-form-leave-note">
                          {t('work_hours_registration_tab.simple_form.leave_on_date')}
                        </span>
                      </Tooltip>
                    )}
                  </Col>
                  <Col span={6}>
                    {decorateField(`${day}-startTime`, {
                      placeholder: t('work_hours_registration_tab.simple_form.time_based.start'),
                      skipLabel: true,
                      skipWrapper: true,
                    })(<Input disabled={disabled} />)}
                  </Col>
                  <Col span={6}>
                    {decorateField(`${day}-endTime`, {
                      placeholder: t('work_hours_registration_tab.simple_form.time_based.end'),
                      skipLabel: true,
                      skipWrapper: true,
                    })(<Input disabled={disabled} />)}
                  </Col>
                  <Col span={5}>
                    {decorateField(`${day}-breakMinutes`, {
                      placeholder: t('work_hours_registration_tab.simple_form.time_based.break'),
                      skipLabel: true,
                      skipWrapper: true,
                      suffix: t('work_hours_registration_tab.simple_form.time_based.break.suffix'),
                      validate: (val) => {
                        if (!val) {
                          return null
                        }
                        if (!val.match(/^([0-9,.]+)/)) {
                          return t('work_hours_registration_tab.simple_form.time_based.break.invalid')
                        }
                        const num = forceParseInputNumber(val)
                        if (num % 1 !== 0) {
                          return t('work_hours_registration_tab.simple_form.time_based.break.must_be_integer')
                        }
                        const startTime = getFieldValue(`${day}-startTime`)
                        const endTime = getFieldValue(`${day}-endTime`)
                        if (
                          startTime &&
                          endTime &&
                          parseTimeAsMinutes(endTime) - parseTimeAsMinutes(startTime) <= num
                        ) {
                          return t('work_hours_registration_tab.simple_form.time_based.break.longer_before_total')
                        }
                        return null
                      },
                    })(<Input disabled={disabled} />)}
                  </Col>
                  <Col span={1} className="text-cell">
                    {(!!getFieldValue(`${day}-startTime`) ||
                      !!getFieldValue(`${day}-endTime`) ||
                      !!getFieldValue(`${day}-breakMinutes`)) && (
                      <span onClick={remove(day)} style={{ cursor: 'pointer' }}>
                        <Icon type="xSign" />
                      </span>
                    )}
                  </Col>
                </Row>
              </Form.Item>
            )
          })}
        </>
      )}
      <Row>
        {disabled && (
          <Col span={8}>
            <Button onClick={() => unlock()} style={{ marginTop: '20px' }}>
              {t('work_hours_registration_tab.simple_form.unlock')}
            </Button>
          </Col>
        )}
        {!disabled && (
          <>
            <Col span={8}>
              <Button htmlType="submit" size="extra-extra-large" type="primary" className="work-hours-week-form-button">
                {t('work_hours_registration_tab.simple_form.submit' + (props.templateMode ? '.template' : ''))}
              </Button>
            </Col>
            {props.templateMode &&
              ((!props.companyTemplate && props.hasEmployeeTemplate) ||
                (props.companyTemplate && props.hasCompanyTemplate)) && (
                <Col span={8}>
                  <Button onClick={props.onDelete} size="extra-extra-large" danger style={{ marginTop: '20px' }}>
                    {t(
                      'work_hours_registration_tab.simple_form.' +
                        (!props.companyTemplate && props.hasCompanyTemplate ? 'delete_with_company' : 'delete')
                    )}
                  </Button>
                </Col>
              )}
          </>
        )}
        {props.templateMode && !props.companyTemplate && (
          <Col span={8}>
            <Button onClick={props.onNone} size="extra-extra-large" style={{ marginTop: '20px' }}>
              {t('work_hours_registration_tab.simple_form.none')}
            </Button>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default withValidations<RegistrationProps | TemplateProps, Fields, WorkHoursResult>({
  mapPropsToFields: (props) => {
    const doTimeBaseRegistration = () => {
      if (
        week.some(
          (day) =>
            props.registrations[day]?.some((reg) => !!reg.start) || props.registrations[day]?.some((reg) => !!reg.end)
        )
      ) {
        // one of the registrations is set
        return true
      }
      return props.company.settingsEnabled.includes('RegisterWorkHoursStartEnd')
    }
    return week.reduce((fields: Fields, day) => {
      const row = props.registrations[day]
      if (row && row.length > 0) {
        const reg = row[0]
        fields[`${day}-hours`] = formatInputNumber(reg.hours)
        if (reg.start !== undefined) {
          fields[`${day}-startTime`] = formatMinutesAsTime(reg.start)
          fields[`${day}-breakMinutes`] = '0'
          if (reg.end !== undefined) {
            fields[`${day}-endTime`] = formatMinutesAsTime(reg.end)
            fields[`${day}-breakMinutes`] = formatInputNumber(
              ((reg.end - reg.start) / 60 - (reg.hours ? reg.hours : 0)) * 60,
              0
            )
          } else if (reg.hours && reg.hours > 0) {
            fields[`${day}-endTime`] = formatMinutesAsTime(reg.start + reg.hours * 60)
          }
        }
      } else if (!doTimeBaseRegistration()) {
        fields[`${day}-hours`] = '0'
      }
      return fields
    }, {})
  },
  onChange: (key, val, allValues, options) => {
    const values = {}
    if (key.match(/.+startTime$/) || key.match(/.endTime$/)) {
      if (options.trigger === 'onBlur') {
        setByPath(values, key, formatInputAsMinutes(val as string))
      } else {
        setByPath(values, key, (val as string).replace(/[ ]/g, ''))
      }
    } else if (key.match(/.+hours$/)) {
      setByPath(values, key, formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' })))
    } else {
      setByPath(values, key, val)
    }
    return values
  },
  onSubmit: (values, props) => {
    const days: ResultDay[] = []
    week.forEach((day) => {
      const result: ResultDay = {
        date: getDateForWeekDay(props.weekStart, day),
        hours: 0,
      }
      const startTime = values[`${day}-startTime`]
      const endTime = values[`${day}-endTime`]
      if (startTime) {
        result.start = parseTimeAsMinutes(startTime)
        if (endTime) {
          result.end = parseTimeAsMinutes(endTime)
          result.hours = (result.end - result.start - forceParseInputNumber(values[`${day}-breakMinutes`])) / 60
        }
      } else {
        result.hours = forceParseInputNumber(values[`${day}-hours`])
      }
      days.push(result)
    })
    return { days }
  },
})(WorkHoursWeekForm)
