import React from 'react'

import Employee from '../../../model/employee'
import { EmployeeReducer } from '../../../reducers/employees'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { setByPath } from '../../../utils/object-utils'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import PaySlipsTransport from './PaySlipsTransport'

// NEED_NO_TRANSLATION

type Props = {
  employee: Employee
  employees: EmployeeReducer
  hasPremiumPackage: boolean
}

export type PaySlipFields = {
  paySlipTransportMitDK: boolean
  paySlipTransportEBoks: boolean
  paySlipTransportEMail: boolean
  paySlipTransportSMS: boolean
}

function PaySlipsForm(props: Props & FormComponentProps<PaySlipFields, PaySlipFields>) {
  return (
    <div className="employees-single-form ">
      <PaySlipsTransport
        hasNationalID={props.employee.nationalIDType !== 'DK No CPR'}
        hasPremiumPackage={props.hasPremiumPackage}
        hasEmail={!!props.employee.email}
        hasDanishPhoneNumber={props.employee.phoneNumberCountryCode === '45'}
        hasPhoneNumber={!!props.employee.phoneNumber}
        isFreelancer={false}
        decorateField={props.decorateField}
      />
      {props.employees.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, PaySlipFields, PaySlipFields>({
  mapPropsToFields: (props) => ({
    paySlipTransportMitDK: props.employee.paySlipTransportMitDK,
    paySlipTransportEBoks: props.employee.paySlipTransportEBoks,
    paySlipTransportEMail: props.employee.paySlipTransportEMail,
    paySlipTransportSMS: props.employee.paySlipTransportSMS,
  }),
  onChange: (key, val) => {
    const values: Partial<PaySlipFields> = {}
    switch (key) {
      case 'paySlipTransportMitDK':
        values.paySlipTransportEBoks = false
        break
      case 'paySlipTransportEBoks':
        values.paySlipTransportMitDK = false
        break
    }
    setByPath(values, key, val)
    return values
  },
  submitOnChange: true,
  onSubmit: (values) => values,
})(PaySlipsForm)
