import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import CarAllowance, { CarAllowanceMutableFields } from '../../../model/carAllowance'
import Company from '../../../model/company'
import CostCenter from '../../../model/costCenter'
import Department from '../../../model/department'
import Employee from '../../../model/employee'
import { CarAllowanceReducer } from '../../../reducers/carAllowances'
import { formatDate } from '../../../utils/date-utils'
import { formatSavingText } from '../../../utils/loading-utils'
import { formatCurrency, formatNumber } from '../../../utils/number-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Icon from '../../elements/icon'
import Modal from '../../elements/modal'
import Table from '../../elements/table'
import Tooltip from '../../elements/tooltip'
import DumbLink from '../../widgets/DumbLink'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import NoContractCard from '../NoContractCard'
import NoEmploymentCard from '../NoEmploymentCard'
import DetailedCarAllowanceEdit from './DetailedCarAllowanceEdit'

type Props = {
  company: Company
  employee: Employee
  costCenters: List<CostCenter>
  departments: List<Department>
  carAllowances: CarAllowanceReducer
  showHistory: boolean
  canEditObjects: boolean
  canApproveObjects: boolean

  addAlert: addAlertSignature
  createCarAllowances: (carAllowance: CarAllowanceMutableFields[]) => void
  updateCarAllowance: (carAllowance: CarAllowanceMutableFields) => void
  approveCarAllowances: (ids: string[]) => void
  unapproveCarAllowances: (ids: string[]) => void
  deleteCarAllowance: (carAllowance: CarAllowance) => void
}

export default function DetailedCarAllowanceTab(props: Props): ReactElement | null {
  const [approving, setApproving] = useState<string[]>([])
  const [deleting, setDeleting] = useState<string[]>([])
  const [modalKey, setModalKey] = useState(1)
  const [editing, setEditing] = useState<string | boolean>(false)
  const [newCarAllowance, setNewCarAllowance] = useState(false)

  const setEditVisibility = (id: string | boolean) => {
    // Increment modalKey to create a new component
    setModalKey((prev) => prev + 1)
    setEditing(id)
    if (id === false) {
      setNewCarAllowance(false)
    }
  }

  const copyCarAllowance = (id: string) => {
    setNewCarAllowance(true)
    setEditVisibility(id)
  }

  const { carAllowances } = props
  const previousCarAllowances = usePrevious(carAllowances)

  useEffect(() => {
    // Check for save callback
    if (previousCarAllowances && previousCarAllowances.saving && !carAllowances.saving) {
      // Check for no error occurred
      if (!carAllowances.error) {
        // Close edit modal
        setEditVisibility(false)
      }
    }
  })

  const approve = (carAllowance: CarAllowance) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setApproving((prev) => [...prev, carAllowance.id])
      props.approveCarAllowances([carAllowance.id])
    }
  }
  const unapprove = (carAllowance: CarAllowance) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setApproving((prev) => prev.filter((id) => id !== carAllowance.id))
      props.unapproveCarAllowances([carAllowance.id])
    }
  }
  const remove = (carAllowance: CarAllowance) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, carAllowance.id])
        props.deleteCarAllowance(carAllowance)
      }
    }
  }

  const getUnsettledKilometers = () => {
    const kilometers = props.carAllowances.carAllowances
      .filter((carAllowance) => carAllowance.employeeID === props.employee.id)
      .reduce((kilometers, carAllowance) => {
        if (!carAllowance.settled) {
          return kilometers + carAllowance.kilometers
        }
        return kilometers
      }, 0)
    return t('car_allowances_tab.detailed.unsettled_kilometers_format', { count: kilometers })
  }

  type CarAllowanceRow = {
    key: string
    id: string
    approved: boolean
    date: string
    licensePlate: string
    distance: string
    rate?: string
    from: string
    to: string
    reason: string
    immutable: boolean
    original: CarAllowance
  }

  const columns = [
    {
      title: t('car_allowances_tab.detailed.table.header.approved'),
      key: 'x1',
      className: 'time-registration-table-approved',
      render: (carAllowance: CarAllowanceRow) => {
        if (carAllowance.approved) {
          return (
            <span>
              {t('car_allowances_tab.detailed.table.approved.true')}
              {props.canApproveObjects && !carAllowance.immutable && (
                <Tooltip title={t('car_allowances_tab.detailed.table.remove_approval')}>
                  <span onClick={unapprove(carAllowance.original)} style={{ cursor: 'pointer' }}>
                    <Icon type="xSign" />
                  </span>
                </Tooltip>
              )}
            </span>
          )
        }
        if (approving.indexOf(carAllowance.id) !== -1) {
          return t('car_allowances_tab.detailed.table.saving')
        }
        if (!props.canApproveObjects) {
          return t('car_allowances_tab.detailed.table.approved.false')
        }
        return (
          <DumbLink onClick={approve(carAllowance.original)} type="standard">
            {t('car_allowances_tab.detailed.table.approve')}
          </DumbLink>
        )
      },
    },
    { title: t('car_allowances_tab.detailed.table.header.date'), dataIndex: 'date', key: 'date' },
    { title: t('car_allowances_tab.detailed.table.header.reason'), dataIndex: 'reason', key: 'reason' },
    {
      title: t('car_allowances_tab.detailed.table.header.kilometers'),
      key: 'x4',
      render: (carAllowance: CarAllowanceRow) => {
        return (
          <Tooltip
            title={t('car_allowances_tab.detailed.table.kilometers_format', {
              from: carAllowance.from,
              to: carAllowance.to,
            })}
          >
            {carAllowance.distance}
            {carAllowance.rate && (
              <>
                <br />
                {t('car_allowances_table.detailed.table.rate_format', { rate: carAllowance.rate })}
              </>
            )}
          </Tooltip>
        )
      },
    },
    {
      title: '',
      key: 'x2',
      className: 'employee-table-actions',
      render: (carAllowance: CarAllowanceRow) => {
        if (deleting.indexOf(carAllowance.id) !== -1) {
          return null
        }
        if (!props.canEditObjects) {
          return null
        }
        return (
          <div>
            <Tooltip title={t('car_allowances_tab.detailed.table.actions.copy')}>
              <span onClick={() => copyCarAllowance(carAllowance.id)} style={{ cursor: 'pointer' }}>
                <Icon type="roundedArrows" />
              </span>
            </Tooltip>
            {!carAllowance.immutable && (
              <>
                <Tooltip title={t('car_allowances_tab.detailed.table.actions.edit')}>
                  <span onClick={() => setEditVisibility(carAllowance.id)} style={{ cursor: 'pointer' }}>
                    <Icon type="paperWithPencil" />
                  </span>
                </Tooltip>
                <Tooltip title={t('car_allowances_tab.detailed.table.actions.delete')}>
                  <span onClick={remove(carAllowance.original)} style={{ cursor: 'pointer' }}>
                    <Icon type="xSign" />
                  </span>
                </Tooltip>
              </>
            )}
          </div>
        )
      },
    },
  ]

  const getCarAllowances = (): CarAllowanceRow[] => {
    return props.carAllowances.carAllowances
      .filter(
        (carAllowance) =>
          carAllowance.employeeID === props.employee.id &&
          ((props.showHistory && carAllowance.settled) || (!props.showHistory && !carAllowance.settled))
      )
      .map((carAllowance) => {
        let reason = carAllowance.reason ?? ''
        if (reason.length > 22) {
          reason = reason.substring(0, 20) + '...'
        }
        return {
          key: carAllowance.id,
          id: carAllowance.id,
          approved: carAllowance.approved,
          date: formatDate(carAllowance.date),
          licensePlate: carAllowance.licensePlate,
          distance: t('car_allowances_tab.detailed.table.kilometer_format', {
            amount: formatNumber(carAllowance.kilometers, 2),
          }),
          rate: carAllowance.rate ? formatCurrency(carAllowance.rate, 2) : undefined,
          from: carAllowance.locationFrom,
          to: carAllowance.locationTo,
          reason,
          immutable: carAllowance.immutable,
          original: carAllowance,
        }
      })
      .toArray()
  }

  const approveAll = (e: React.MouseEvent) => {
    e.preventDefault()
    if (window.confirm(t('common.are_you_sure'))) {
      const carAllowances = getCarAllowances()
        .filter((reg) => !reg.approved)
        .map((reg) => reg.original)
      setApproving(carAllowances.map((allowance) => allowance.id))
      props.approveCarAllowances(
        carAllowances
          .filter((carAllowance) => carAllowance.employeeID === props.employee.id)
          .map((allowance) => allowance.id)
      )
    }
  }

  const hasUnapprovedCarAllowances = () => {
    return getCarAllowances().filter((reg) => !reg.approved).length > 0
  }

  if (!props.employee.activeEmployment) {
    return <NoEmploymentCard />
  }
  if (
    !props.employee.activeContract ||
    props.employee.activeEmployment.id !== props.employee.activeContract.employmentID
  ) {
    return <NoContractCard employee={props.employee} />
  }

  return (
    <div>
      {!props.showHistory && (
        <>
          <p>{t('car_allowances_tab.detailed.header.title')}</p>
          <Row>
            <Col span={16}>
              <div className="time-registration-stat">
                <strong>{getUnsettledKilometers()}</strong>
                <span className="time-registration-hours">
                  {t('car_allowances_tab.detailed.header.unsettled_suffix')}
                </span>
              </div>
            </Col>
            <Col span={8} className="time-registration-buttons">
              {props.canEditObjects && (
                <Button onClick={() => setEditVisibility(true)} prefixIcon="plusCircle" type="primary">
                  {t('car_allowances_tab.detailed.header.add_registration')}
                </Button>
              )}

              {hasUnapprovedCarAllowances() && props.canApproveObjects && (
                <Button onClick={approveAll} style={{ marginLeft: 20 }}>
                  {t('car_allowances_tab.detailed.header.approve_all')}
                </Button>
              )}
            </Col>
          </Row>
        </>
      )}

      <Table columns={columns} dataSource={getCarAllowances()} pagination={false} />
      {props.carAllowances.saving && (
        <LoadingOverlay
          text={formatSavingText([{ loading: props.carAllowances.saving, text: t('loading.reducer.car_allowances') }])}
        />
      )}

      <Modal
        key={modalKey}
        visible={editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={980}
        footer={null}
      >
        <DetailedCarAllowanceEdit
          visible={editing !== false}
          newCarAllowance={newCarAllowance}
          company={props.company}
          employee={props.employee}
          costCenters={props.costCenters}
          departments={props.departments}
          carAllowanceID={typeof editing === 'string' ? editing : undefined}
          carAllowances={props.carAllowances}
          createCarAllowances={props.createCarAllowances}
          updateCarAllowance={props.updateCarAllowance}
        />
      </Modal>
    </div>
  )
}
